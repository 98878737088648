import './App.css';
import BasicCard from './components/cards';

function App() {
  const queryParameters = new URLSearchParams(window.location.search)
  let token = queryParameters.get("token") ? queryParameters.get("token") : "xZzXioZSv5QJMwz0QchTlGVHZjsZhVOuhN7cK95z6Ro7o96vpNcsM4kCaU9z3D7WWf6j7EkkHktnI8eJNziFoENip9bC5CEnS5JANV1PpNSklaGBDDX1JcpEwOIypzGWMy7Oa6QB4wELmdkyAMrPfkYOya/1dO7YSFdDu7gRNav84RfdCKFcsO28i6o9 IZlIy9YwbwBWmI0qP/EumPQ1w=="
  console.log("Tokennn", token)
  return (
    <div >
      <BasicCard token={token} />
    </div>
  );
}

export default App;
