import { CircularProgress, TextField, Typography, useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import axios from 'axios';
import * as React from 'react';
import AlertDialog from './modal';
import Logo from './tde-logo.png';

export default function BasicCard(props) {
    // const { token } = props
    const [body, setBody] = React.useState({
        "Company name": "", "Focal contact person for service setup": "",
    })

    const [open, setOpen] = React.useState(false)
    const [message, setMessage] = React.useState("")
    const [title, setTitle] = React.useState("")
    const [disabled, setDisabled] = React.useState(true)


    const queryParameters = new URLSearchParams(window.location.search)
    let newToken = queryParameters.get("token")
    // ? queryParameters.get("token") 
    // : "xZzXioZSv5QJMwz0QchTlGVHZjsZhVOuhN7cK95z6Ro7o96vpNcsM4kCaU9z3D7WWf6j7EkkHktnI8eJNziFoENip9bC5CEnS5JANV1PpNSklaGBDDX1JcpEwOIypzGWMy7Oa6QB4wELmdkyAMrPfkYOya/1dO7YSFdDu7gRNav84RfdCKFcsO28i6o9 IZlIy9YwbwBWmI0qP/EumPQ1w=="
    // const [accessToken, setAccessToken] = React.useState("")
    const [showPassword, setShowPassword] = React.useState(false)
    const [showConfirmPass, setShowConfirmPass] = React.useState(false)
    const [isValid, setIsValid] = React.useState(true)

    const [showCircle, setShowCircle] = React.useState(false)
    const matches = useMediaQuery('(min-width:700px)');

    // const [checkValidateTokenJson, setcheckValidateTokenJson] = React.useState({})

    const checkIfAllFildsAreFill = () => {
        if ((!!!Object.keys(body).find(item => !body[item].trim()))) {
            setIsValid(true)
        }
        else {
            setIsValid(false)

        }

    }


    const handleChange = (item, value) => {
        let newBody = { ...body }
        newBody = { ...newBody, [item]: value }

        setBody(newBody)
    }

    React.useEffect(() => {// should be executed once (when the node is dropped only)
        checkValidateToken();

    }, [newToken]);// eslint-disable-line

    React.useEffect(() => {// should be executed once (when the node is dropped only)
        checkIfAllFildsAreFill();
    }, [body]);// eslint-disable-line

    const checkValidateToken = () => {
        let data = {
            token: newToken

        };
        var config = {
            method: "Post",
            url: process.env.REACT_APP_URL + "api/saasTokenValidation",
            headers: {
                "Content-Type": "application/json",
                // Authorization: "Bearer " + token,
            },
            data: data,
        };

        axios(config)
            .then((response) => {
                console.log("checkValidateToken Success", response.data);
                // setcheckValidateTokenJson(response.data)
                if (response.data.hasOwnProperty("valid") && response.data.valid === false)
                    setDisabled(true);
                else setDisabled(false)
            })
            .catch((error) => {
                console.log("checkValidateToken Faild", error);

                setMessage(
                    <>
                        <Typography style={{ margin: "0px 130px 0px 0px" }}>
                            Invalid Token !
                        </Typography>
                    </>
                );
                setTitle("Error");
                setOpen(true);
                setDisabled(true);
            });
    };


    const handleSubmit = () => {
        let data =
        {   "token":newToken,
            "purchaser_informations": {
                "resgistration": {
                    "company_name": body['Company name'],
                    "contact_person": body['Focal contact person for service setup']
                }
            }
        }
        setShowCircle(true)
        var config = {
            method: "Post",
            url: process.env.REACT_APP_URL + "api/signup",
            headers: {
                "Content-Type": "application/json",
                // Authorization: "Bearer " + token,
            },
            data: data,
        };

        axios(config)
            .then((response) => {
                console.log('checkValidateToken Success', response.data);
                // setcheckValidateTokenJson(response.data)

                setMessage(
                    <>
                        <Typography>
                            An account manager from <strong style={{color:"#2A539D"}}>tde</strong> will contact you shortly
                        </Typography>
                        <Typography style={{ marginTop: "13px" }}>
                            Have a nice day!
                        </Typography>
                    </>)
                setTitle("Thank you for your purchase!")
                setOpen(true)
                setShowCircle(false)



            })
            .catch((error) => {
                console.log('checkValidateToken Faild', error);
                setShowCircle(false)


            });
    };

    return (
        <div style={{
            position: "fixed", /* or absolute */
            top: "50%",
            left: "50%", transform: "translate(-50%, -50%)", width: "85%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column",
            border: "1px solid #2A539D", padding: "10px"
        }}>
            {open &&
                < AlertDialog
                    open={open}
                    setOpen={setOpen}
                    body={message}
                    title={title} />


            }
            <div style={{
                textAlign: "center",
            }}>

                <img
                    src={Logo}
                    alt={"logo"}
                    width={"80%"}
                />
            </div>
            <div style={{
                textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", width: "100%"

            }}>
                {Object.keys(body).map((item) => {
                    return (
                        <>
                            <TextField
                                sx={{ input: { cursor: disabled ? "not-allowed" : "" } }}
                                value={body[item]}

                                onChange={(e) => handleChange(item, e.target.value)}
                                disabled={disabled}
                                style={{ width: matches ? "50%" : "85%", margin: "12px" }} id={item} label={item} variant="outlined"
                                inputProps={{
                                    autocomplete: 'new-password',
                                    form: {
                                        autocomplete: 'off',
                                    },
                                }}
                            />
                        </>


                    )

                })}


                <Button
                    onClick={() => !showCircle && handleSubmit()}
                    className='registerButton'
                    disabled={(!isValid || disabled)} variant="contained">
                    Register
                    {showCircle &&
                        <CircularProgress size={"15px"} style={{ marginLeft: "13px", color: "whitesmoke" }} />

                    }


                </Button>
            </div>

        </div>



    );
}